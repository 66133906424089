export function useFormatters() {
  const { $md } = useNuxtApp()

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return ''

    const countryCode = '+31'

    const formattedPhoneNumber = phoneNumber
      .replace(/^0+/, '')
      .replace(/[^0-9]/g, '')

    return `${countryCode}${formattedPhoneNumber}`
  }

  const formatPrice = (price: number) => {
    if (!price && price !== 0) return ''

    return price.toLocaleString('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    })
  }

  const formatMarkdown = (text: string) => {
    return $md(text)
  }

  const formatDate = (
    date: string,
    options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
  ) => {
    return new Date(date).toLocaleDateString('nl', options)
  }

  return {
    formatPhoneNumber,
    formatMarkdown,
    formatDate,
    formatPrice,
  }
}
